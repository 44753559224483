<template>
    <div id="app" style="overflow-y: hidden">
      <b-navbar type="light" class="top-navbar" id="navbar">
        <b-nav class="mx-auto logo-col">
          <b-nav-text>
            <div class="row" style="text-align: center">
              <div class="header-dirt-logo col-12 text-center">
                <a href="https://www.natgeokids.com/uk/dirtisgoodacademy/" target="_blank"><img src="./assets/img/logo.png"></a>
              </div>
            </div>
          </b-nav-text>
        </b-nav>
      </b-navbar>
        <div class="content-wrapper" id="mainContent" ref="app" >
            <router-view/>
        </div>
        <b-navbar type="light" class="footer site-footer" id="footer">
            <span class="dirt-logo"></span>
             <b-nav class="mx-auto">
               <b-nav-item class="first" :to="{ name: 'ScoreBoard' }">View scoreboard</b-nav-item>
                <b-nav-item href="https://www.persil.com/uk/home.html" target="_blank">Persil.com</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'cookie-policy-uk' } }">Cookie policy</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'privacy-policy' } }">Privacy policy</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'terms-of-use' } }">Terms of use</b-nav-item>
                <b-nav-item :to="{ name: 'Info', params: { pageSlug: 'legal' } }">Legal</b-nav-item>
             </b-nav>
        </b-navbar>
      <cookie-control></cookie-control>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import CookieControl from './views/CookieControl'
export default {
  components: { CookieControl },
  name: 'App',
  methods: {
    ...mapActions(['setScoreBoard'])
  }
}
</script>

<style>
.footer {
  background-color: #ccced2;
}

.top-navbar {
  height: 100px;
}

div.content-wrapper, div.content-wrapper * {
  /* border: 1px solid transparent */
}

.site-footer {
  bottom: 0;
  width: 100%
}
</style>
