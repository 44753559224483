import Vue from 'vue'
import Vuex from 'vuex'
import data from './modules/data'
import score from './modules/score'
import VuexPersist from 'vuex-persist'

const dataStore = (new VuexPersist({
  modules: ['data']
})).plugin

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    data,
    score
  },
  plugins: [
    dataStore
  ]
})
