import api from '../../api'
import avatars from '../../avatars'
const state = {
  userName: '',
  avatar: 0,
  score: null
}

const getters = {
  userName (state) {
    return state.userName
  },
  score (state) {
    return state.score
  },
  avatar (state) {
    return avatars[state.avatar]
  }
}

const actions = {
  setScore (context, data) {
    return api.setScore({ username: context.getters.userName, score: data }).then(res => {
      if (res.msg) {
        context.commit('setScore', data)
      }
      return res
    })
  },
  setUser (context, data) {
    return api.setUser({ username: data.username, avatar: data.avatar }).then(res => {
      if (res.msg) {
        context.commit('setUser', data.username)
      }
      return res
    })
  },
  resetUser (context) {
    context.commit('setUser', '')
    context.commit('setScore', null)
  }
}

const mutations = {
  setUser (state, data) {
    state.userName = data
  },
  setScore (state, data) {
    state.score = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
