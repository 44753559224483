import axios from 'axios/index'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

export default {
  setUser (data) {
    return http.post('/set_user', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  setScore (data) {
    return http.post('/set_score', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  },
  getScoreBoard (data) {
    return http.get('/get_score', data, { headers: { 'Content-Type': 'application/json' } }).then(resp => resp.data)
  }
}
