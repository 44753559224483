import api from '../../api'

const state = {
  scoreboard: []
}

const getters = {
  scoreboard (state) {
    return state.scoreboard
  }
}

const actions = {
  setScoreBoard (context) {
    return api.getScoreBoard().then(data => {
      if (Array.isArray(data)) {
        console.log('set scoreboard')
        context.commit('setScoreBoard', data)
      }
    })
  }
}

const mutations = {
  setScoreBoard (state, data) {
    state.scoreboard = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
