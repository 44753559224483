<template>
  <div>
    <div class="cc-animate cc-revoke cc-bottom" @click="remove()"></div>
    <vue-cookie-accept-decline
        :ref="'cookieControl'"
        :elementId="'cookieControl'"
        :debug="false"
        :position="'bottom-left'"
        :type="'floating'"
        :disableDecline="false"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false"
        @status="cookieStatus"
        @clicked-accept="cookieClickedAccept"
        @clicked-decline="cookieClickedDecline">

      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
        <router-link :to="{ name: 'Info', params: { pageSlug: 'cookie-policy-uk' } }" target="_blank">Learn More...</router-link>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        GOT IT!
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

export default {
  name: 'CookieControl',
  components: {
    VueCookieAcceptDecline
  },
  data () {
    return {
      status: null
    }
  },
  methods: {
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
      this.$gtag.optIn()
    },
    cookieClickedDecline () {
      this.status = 'decline'
      this.$gtag.optOut()
    },
    remove () {
      this.status = null
      this.$refs.cookieControl.removeCookie()
      this.$refs.cookieControl.init()
    }
  },
  mounted () {
    if ([null, 'decline'].includes(localStorage.getItem('vue-cookie-accept-decline-cookieControl'))) {
      this.$gtag.optOut()
    } else {
      this.$gtag.optIn()
    }
  }
}
</script>

<style scoped>

</style>
