import Vue from 'vue'
import VueRouter from 'vue-router'
const Avatar = () => import(/* webpackChunkName: "avatar" */ '../views/Avatar')
const Quiz = () => import(/* webpackChunkName: "quiz" */ '../views/Quiz')
const Info = () => import(/* webpackChunkName: "info" */ '../views/Info')
const Score = () => import(/* webpackChunkName: "info" */ '../views/Score')
const ScoreBoard = () => import(/* webpackChunkName: "info" */ '../views/ScoreBoard')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Avatar',
    component: Avatar
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/score',
    name: 'Score',
    component: Score
  },
  {
    path: '/scoreboard',
    name: 'ScoreBoard',
    component: ScoreBoard
  },
  {
    path: '/info/:pageSlug',
    name: 'Info',
    component: Info
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
